/* desabilitar copy pages de la pagina  */

* {
    user-select: none;
}

*::selection {
    background: none;
}

*::-moz-selection {
    background: none;
}

/* fin */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Jost", sans-serif;
    /* font-family: cursive; */
}

/* Estilo del modal  */
.modalServicesColumnaReverse {
    display: flex;
    flex-direction: row-reverse;
}

.modalServicesColumna,
.modalServicesColumnaReverse {
    padding: 0px 30px 50px;
}

.infoModalContent,
.imgModalContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoModalContent div {
    padding: 0 30px;
}

.imgModalContent img {
    border-radius: 20px;
}

.modalServicesColumna .imgModalContent img {
    box-shadow: rgba(252, 183, 81, 0.4) -5px 5px, rgba(252, 183, 81, 0.3) -10px 10px, rgba(252, 183, 81, 0.2) -15px 15px, rgba(252, 183, 81, 0.1) -20px 20px, rgba(252, 183, 81, 0.05) -25px 25px;
}

.modalServicesColumnaReverse .imgModalContent img {
    box-shadow: rgba(252, 183, 81, 0.4) 5px 5px, rgba(252, 183, 81, 0.3) 10px 10px, rgba(252, 183, 81, 0.2) 15px 15px, rgba(252, 183, 81, 0.1) 20px 20px, rgba(252, 183, 81, 0.05) 25px 25px;

}

.modal-title {
    font-size: 45px;
}

/* modo responsive modal */

@media screen and (max-width: 820px) {
    .modal-title {
        font-size: 35px;
    }


    .infoModalContent div {
        padding: 30px 0;
    }
}

@media screen and (max-width: 540px) {
    .modal-title {
        font-size: 25px;
    }

    .infoModalContent div p {
        text-align: center;
    }

    .infoModalContent {
        padding: 0;
    }
}

/* Services Home */
.servicesHome__title {
    font-size: 80px !important;
    font-weight: bold;
    text-transform: capitalize;
}

@media screen and (max-width: 540px) {
    .servicesHome__title {
        font-size: 50px !important;
        font-weight: bold;
        text-transform: capitalize;
    }
}

/* About Two */
.aboutTwo__img {
    width: 962px;
    height: 692px;
}

@media screen and (max-width: 540px) {
    .aboutTwo__img {
        width: 100%;
        height: auto;
    }
}

/* Values Homes */
.valuesContent__text {
    max-width: 100% !important;
    padding: 0 20px !important;
}

/*Home Content One*/
.homeContentOne__image {
    width: 586px;
    height: 666px;
}

@media screen and (max-width: 540px) {
    .homeContentOne__image {
        width: 100%;
        height: auto;
    }
}

.directorioContent {
    display: flex !important;
    align-items: center !important;
}

/*slider de directorio home*/

.swiper-wrapper {
    display: flex;
    align-items: center;
}

/* Home Content services*/
.homeContent__services {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

/* contenedor de redes sociales */
.contentRedes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}
.contentRedesColumns{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    gap: 20px;
}

.contentRedes div {
    margin: 0 15px;
    width: 20%;
}

.contentRedes img {
    width: 100%;
}
@media screen and (max-width: 798px){
    .contentRedesColumns{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 540px) {
    .contentRedes {
        flex-direction: column;
    }
    .contentRedesColumns{
        grid-template-columns: 1fr;
    }

    .contentRedes div {
        width: 100%;
        margin: 0;
        padding: 0 20px 40px;
    }
}


/*  formulario */

.formContent{
    margin: 0 !important;
    width: 100% !important;
}

.form-control{
    border-radius: 100px !important;
    height: 63px !important;
    background-color: #f1f1f1 !important;
    color: #7e7e7e !important;
    padding-left: 30px !important;
    font-size: 16px !important;
}
.formContent__messages{
    height: 150px !important;
    border-radius: 30px !important;

}
.formContent__checkOpt{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.formContent__btn{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 0px !important;
    width: 240px !important;
    padding: 34.5px 57.5px !important;
    background-color: var(--thm-base) !important;
    color: #fff !important;
    border-bottom-left-radius: 31.5px !important;
    border-top-left-radius: 31.5px  !important;
}

/* fin */